import React from 'react';
import { graphql } from 'gatsby';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quote from '../components/Quote';
import Section from '../components/Section';
import Video from '../components/Video';
import MultiVideoPlayer from '../components/MultiVIdeoPlayer';
import Callout from '../components/Callout';
import Resource from '../components/Resource';
import { getKeyedResources } from '../utils/resource-helper';


const VirtualFieldTrip = ({ data }) => {
  const {
    awardCeremony,
    makesAClassic,
    mathBehindMusic
  } = getKeyedResources(data);

  return (
  <Layout title={'Virtual Field Trip'}>
    <Hero
      mediaSource="vft-hero.jpeg"
      textOverlay="VIRTUAL FIELD TRIP"
      className="mb-0"
    />
    {/*Uncomment to show the Feedback banner at top of page */}
    {/* <Container fullWidth noPadding id="vft">
      <Callout className="vft-callout" to="https://surveys.discoveryeducation.com/s3/CMA-Working-in-Harmony" target="_blank">
        <p className="vft-callout--big">
          What did you think about the Virtual Field Trip? <br/>
          Share your thoughts and feedback with us today!
        </p>
      </Callout>
    </Container> */}

    <Section noPadding className="vft-intro">
      <Container fullWidth className="vft-intro__container">
        <div className="vft-intro__container__div">
          <div className="vft-intro__container__div__text">
            <h1 className="vft-intro__container__div__text__title">
              STEAM Careers Take the Spotlight Virtual Field Trip: Country Music's Biggest Night&trade;
            </h1>

          </div>
          <div className="vft-intro__container__div__image-div">
            <Image
              className="vft-intro__container__div__image-div__image"
              filename="vft-graphic-piano.svg"
            />
          </div>
        </div>

        {/* VIDEO Chaptered */}
        <Row className="vft-intro__container__row" >

          <Column className="vft-intro__container__row__video" size={9}>
            <MultiVideoPlayer
              videos={[
                {
                  guidSrc: '161a2f1e-e740-438a-b47e-5168a4ab9ff9',
                  poster: 'VFT-Thumb-FullVideo@2x.jpg',
                  title: 'Full Video',
                },
                {
                  guidSrc: '4dd6244b-2e61-40ed-bd22-c62e9f43bbd9',
                  poster: 'VFT-Thumb-Chapter1@2x.jpg',
                  title: 'Chapter 1: Control Room Chaos',
                },
                {
                  guidSrc: 'd84fb3b4-9b99-4804-bc9e-4b9c456ef341',
                  poster: 'VFT-Thumb-Chapter2@2x.jpg',
                  title: 'Chapter 2: Timing the Show',
                },
                {
                  guidSrc: 'ee60a86c-f77b-4e57-be05-6b3517ce9393',
                  poster: 'VFT-Thumb-Chapter3@2x.jpg',
                  title: 'Chapter 3: STEAM Principles on Set',
                },
                {
                  guidSrc: 'beacb144-75e4-42ca-a22d-0e5e59ed45d4',
                  poster: 'VFT-Thumb-Chapter4@2x.jpg',
                  title: 'Chapter 4: Meet the CMA Glam Squad',
                },
                {
                  guidSrc: '65e2a431-470b-4104-9618-1431fceb9f11',
                  poster: 'VFT-Thumb-Chapter5@2x.jpg',
                  title: 'Chapter 5: Wrap It Up in Post Production',
                },
              ]}
            />
            {/* <Video guidSrc="161a2f1e-e740-438a-b47e-5168a4ab9ff9" /> */}
          </Column>
        </Row>
        <Row fullWidth className="mt-1">
            <h2>
            About the Virtual Field Trip
            </h2>
            <p className="vft-intro">
              Discover the STEAM careers behind a live award show and the creativity, collaboration, critical thinking, and communication needed to put on this televised event. Go behind the scenes of this dazzling night to meet the people whose job it is to make it all happen. Students will see how the strategy of decomposition helps break down a big event into smaller manageable problems. They will have the opportunity to see a variety of non-traditional career paths that come together and see how music can be used to communicate personal experiences and passions.
            </p>
          </Row>
      </Container>

      {/* Ed Guide */}
      <Container fullWidth className="ed-guide bg-white">
        <div className="ed-guide__right-column">
          <div className="ed-guide__right-column__img-type">
            <h4 className="m-0">Educator Guide</h4>
          </div>
          <Image
            className="ed-guide__right-column__img-wrapper"
            filename="vft-awards-rehearsal.jpg"
            objectFit="cover"
          />
        </div>
        <div className="ed-guide__left-column">
          <h3 className="color-primary-blue ed-guide__title">
            Virtual Field Trip | Educator Guide
          </h3>
          <p className="small ed-guide__audience">Grades 6–12</p>
          <p>
            Engage students in key topics covered in the Virtual Field Trip with
            a variety of supporting resources, including pre- and post-viewing
            student activities. Use this companion guide to find helpful
            background information, standards alignment information and more.{' '}
          </p>
          <div className="d-flex align-items-center">
            <Button target="_blank" to="/docs/CMA-VFT-EdGuide.pdf">
              <Icon name="download" marginRight marginLeft />
              DOWNLOAD
            </Button>
            <p className="small pl-1">.PDF, 587KB</p>
          </div>
        </div>
      </Container>
      <Container fullWidth className="vft-activities__container bg-white" id="vft-activities">
        <Row>
          <Column size={4}>
            <Resource
              tophat={awardCeremony.tophat}
              title={awardCeremony.title}
              audience={awardCeremony.audience}
              duration={awardCeremony.duration}
              className={awardCeremony.className}
              subtitle={awardCeremony.subtitle}
              description={awardCeremony.description}
              img={awardCeremony.img}
              actions={awardCeremony.actions}
            />
          </Column>
          <Column size={4}>
            <Resource
              tophat={makesAClassic.tophat}
              title={makesAClassic.title}
              audience={makesAClassic.audience}
              duration={makesAClassic.duration}
              className={makesAClassic.className}
              subtitle={makesAClassic.subtitle}
              description={makesAClassic.description}
              img={makesAClassic.img}
              actions={makesAClassic.actions}
            />
          </Column>
          <Column size={4}>
            <Resource
              tophat={mathBehindMusic.tophat}
              title={mathBehindMusic.title}
              audience={mathBehindMusic.audience}
              duration={mathBehindMusic.duration}
              className={mathBehindMusic.className}
              subtitle={mathBehindMusic.subtitle}
              description={mathBehindMusic.description}
              img={mathBehindMusic.img}
              actions={mathBehindMusic.actions}
            />
          </Column>
        </Row>
       </Container>
    </Section>

    <Quote
      text="Music is a universal language we all speak, we all love, and we all can find something in common with."
      source="Aaron Farmer, Musical Director for Florida Georgia Line"
      backgroundImage="vft-block-quote.png"
      className="bg-white"
    />
  </Layout>
  );
};

export const query = graphql`
  query vftActivities {
    allActivitiesJson(
      filter: {
        pages: {
          in: ["vft-activities"]
        }
      }
    ) {
      edges {
        node {
          slug
          tophat
          img
          icon
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            moduleName
            link
            download
            noIcon
            asLink
          }
          className
          pages
        }
      }
    }
  }
`;

export default VirtualFieldTrip;